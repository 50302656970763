import { Word } from '@models/word';
import { createReducer, on, Action } from '@ngrx/store';


import {
  loadTopics,
  loadWordList,
  resetGame,
  setActivity,
  setIntroPlayed,
  setMessages,
  setTopicLoaded,
  setWordList
} from './spelling.actions';

export const spellingStateFeatureKey = 'spelling';

export interface SpellingState {
  grade: string;
  unit: string;
  level?: any;
  wordListLoaded: boolean;
  wordList: Word[];
  introPlayed: boolean;
  activity: string;
  topicLoaded: boolean;
  messages: string[];
}

export const initialState: SpellingState = {
  wordListLoaded: false,
  wordList: null,
  grade: null,
  unit: null,
  level: null,
  introPlayed: null,
  activity: null,
  topicLoaded: false,
  messages: null,
};

export const spellingReducer = createReducer(
  initialState,
  on(resetGame, state => ({ ...state, wordListLoaded: false })),
  on(loadWordList, (state, { grade, unit, level }) => ({
    ...state,
    wordList: null,
    wordListLoaded: false,
    grade,
    unit,
    level,
  })),
  on(loadTopics, state => ({ ...state, topicActivities: null, topicLoaded: false, })),
  on(setWordList, (state, { wordList }) => ({
    ...state,
    wordList,
    wordListLoaded: true,
  })),
  on(setIntroPlayed, (state, { introPlayed }) => ({ ...state, introPlayed })),
  on(setActivity, (state, { activity }) => ({ ...state, activity })),
  on(setTopicLoaded, (state, { topicLoaded }) => ({ ...state, topicLoaded })),
  on(setMessages, (state, { messages }) => ({ ...state, messages })),
);

export function reducer(state: SpellingState | undefined, action: Action) {
  return spellingReducer(state, action);
}
