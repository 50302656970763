import { Word } from '@models/word';
import { createAction, props } from '@ngrx/store';


export enum SPELLING_ACTIONS {
  initGame = '[Spelling] Initialize Game',
  resetGame = '[Spelling] Reset Game',
  loadWordList = '[Spelling] Load Word List',
  loadTopics = '[Spelling] Load Topics',
  setWordList = '[Spelling] Set Word List',
  setMessages = '[Spelling] Set Messages',
  setIntroPlayed = '[Spelling] Set Intro Played',
  setActivity = '[Spelling] Set Activity',
  setTopicLoaded = '[Spelling] Set Topic Loaded',
  resetActivity = '[Spelling] Reset Activity',
}

export const initGame = createAction(SPELLING_ACTIONS.initGame);
export const resetGame = createAction(SPELLING_ACTIONS.resetGame);
export const loadWordList = createAction(SPELLING_ACTIONS.loadWordList, props<{
  grade: string,
  unit: string,
  level: any,
}>());
export const loadTopics = createAction(SPELLING_ACTIONS.loadTopics, props<{ topicKey: string }>());
export const setWordList = createAction(SPELLING_ACTIONS.setWordList, props<{ wordList: Word[] }>());
export const setIntroPlayed = createAction(SPELLING_ACTIONS.setIntroPlayed, props<{ introPlayed: boolean }>());
export const setActivity = createAction(SPELLING_ACTIONS.setActivity, props<{ activity: string }>());
export const setTopicLoaded = createAction(SPELLING_ACTIONS.setTopicLoaded, props<{ topicLoaded: boolean }>());
export const resetActivity = createAction(SPELLING_ACTIONS.resetActivity, props<{ activity: string }>());
export const setMessages = createAction(SPELLING_ACTIONS.setMessages, props<{ messages: string[] }>());
