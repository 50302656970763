import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IUserLegacy } from '@models/user';
import { Products } from '@shared/products.enum';
import { of, Observable } from 'rxjs';

import { BootstrapService } from './bootstrap.service';

@Injectable()
export class PreferenceService {
  constructor(private bootstrap: BootstrapService, private http: HttpClient) { }

  getPreferenceUrl(user: IUserLegacy, definition: string): string {
    return `/dpd-api/3/user/${user.uid}/preferences/${definition}.json`;
  }

  get definitionName(): string {
    switch (this.bootstrap.product) {
    case Products.spelling:
      return 'spcn2020';

    case Products.handwriting:
      return 'hw2020';

    default:
      return 'global';
    }
  }

  get activityPreferences(): any {
    const { preferences } = this.bootstrap;

    if (preferences
      && preferences[this.definitionName]
      && preferences[this.definitionName][this.bootstrap.activity]) {
      return preferences[this.definitionName][this.bootstrap.activity];
    }

    return null;
  }

  // @todo rewrite.
  getPreference(definition: string, activity: string, preference: string): any {
    const { preferences } = this.bootstrap;

    if (preferences[definition]
      && preferences[definition][activity]
      && preferences[definition][activity][preference]) {
      return preferences[definition][activity][preference];
    }

    return null;
  }

  // @todo rewrite.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  savePreference(definition: string, preference: string, name: string, value: any): Observable<boolean> {
    return of(true);
  }
}
